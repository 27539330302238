import { ISearchResult } from 'api/common.types'
import { IOrderBy } from 'api/odata.types'
import { ISecurity } from 'api/security.types'
import { AppState } from 'store'
import { createAsyncReducer, createAsyncSelectors, search } from 'store/shared'
import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'

export const SecurityToOverrideFetchActions = createAsyncAction(
  '@modules/@security/@overrides/@search/REQUEST',
  '@modules/@security/@overrides/@search/SUCCESS',
  '@modules/@security/@overrides/@search/FAILURE'
)<string, ISecurity[], Error>()

export const securityToOverrideFetchReducer = createAsyncReducer(
  SecurityToOverrideFetchActions
)

const rootSelector = (state: AppState) =>
  state.modules.overrides.securityToOverride

export const {
  getError: getSecurityToOverridesError,
  getIsLoading: getSecurityToOverridesLoading,
  getResult: getSecurityToOverridesResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof SecurityToOverrideFetchActions.request>
) {
  try {
    if (action.payload === 'RESET-MODAL') {
      yield put(SecurityToOverrideFetchActions.success([]))
    } else {
      const orderBy: IOrderBy[] = [
        {
          direction: 'desc',
          dataPath: 'search.score()'
        },
        {
          direction: 'desc',
          dataPath: 'securitydescription'
        }
      ]

      const result: ISearchResult<ISecurity> = yield call(
        search,
        'security' as const,
        {
          top: 100,
          count: true,
          fullQuery: true,
          query: action.payload + '*',
          orderBy: orderBy,
          searchFields: ['securitydescription', 'cusip']
        }
      )
      yield put(SecurityToOverrideFetchActions.success(result.value))
    }
  } catch (e: any) {
    yield put(SecurityToOverrideFetchActions.failure(e))
  }
}

export const securityToOverrideFetchSagas = [
  () => takeLatest(SecurityToOverrideFetchActions.request, onRequest)
]
